import {useGetGroupShoutsQuery} from "../services/apiClient";
import {PropsWithChildren} from "react";
import {Avatar, Typography} from "@mui/material";
import {assetUrl} from "../services/image";

interface ShoutOutProps extends PropsWithChildren {
    name: string;
    value: number;
    unit?: string | null
    milestone: "reps" | "total volume" | "sets" | "sessions";
}

function ShoutOut({name, value, unit, milestone, children}: ShoutOutProps) {
    return <div className={'rounded bg-black/25 py-4 px-2 flex items-center'}>
        <div className={'flex-0 mx-2'}>{children}</div>
        <p className={'flex-grow'}><strong>{name}</strong> hit <strong>{value.toLocaleString()}{unit}</strong> {milestone}!</p>
    </div>
}


interface ShoutOutsProps {
    groupId: number;
    className?: string;
}

function ShoutOuts({groupId, className}: ShoutOutsProps) {
    const {data} = useGetGroupShoutsQuery(groupId);
    if (!data) {
        return <></>
    }
    console.log(data);
    let rows;
    if (data && data.data.entities && data.data.entities.shoutOuts) {
        // everything right now is a shout out milestone so we will need to grab the shout out milestone too
        const {shoutOuts, shoutOutMilestones, users} = data.data.entities;
        rows = data.data.result.map((id: number) => {
            let shoutOut = shoutOuts[id];
            let milestone = shoutOutMilestones[shoutOut.shout_out_milestone];
            let user = users[shoutOut.user_id];
            let shortName = user.first_name.slice(0, 1) + user.last_name.slice(0, 1);
            return <ShoutOut name={user?.first_name + " " + user?.last_name}
                             value={milestone.target}
                             milestone={milestone.attribute}
                             unit={milestone.attribute === 'volume' ? 'kg' : ''}
                             key={shoutOut.id}
            >
                {user.avatar_url ? <Avatar src={assetUrl(user.avatar_url, "md")} sx={{width: 30, height: 30}}/> :
                    <Avatar sx={{width: 30, height: 30}}><Typography>{shortName}</Typography></Avatar>}
            </ShoutOut>
        })
    }
    return <div className={className}>
        <p className={"font-bold text-left mb-4"}>Activity Feed</p>
        {rows}
    </div>
}

export default ShoutOuts;
