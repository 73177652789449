import {useGetGroupQuery} from "../services/apiClient";
import CarouselItem from "./carousel/CarouselItem";
import ActiveChallengeDashboard from "./group-challenge/ActiveChallengeDashboard";
import Carousel from "./carousel/Carousel";
import React from "react";
import {GroupLeaderboard} from "../Interfaces";
import Leaderboard from "./Leaderboard";

interface GroupCarouselProps {
    groupId: number;
    className?: string;
}

function GroupCarousel({groupId, className}: GroupCarouselProps) {
    const {data} = useGetGroupQuery(groupId);
    let items: GroupLeaderboard[] = [];
    if (data && data.data.entities && data.data.entities.leaderboards) {
        items = Object.keys(data.data.entities.leaderboards).map(id => {
            let leaderboard = {...data.data.entities.leaderboards[id]};
            leaderboard.lift_variation = data.data.entities.liftVariations[leaderboard.lift_variation_id];
            return leaderboard;
        });
    }
    return <Carousel className={className}>
        <CarouselItem><ActiveChallengeDashboard groupId={+groupId}/></CarouselItem>
        {items.map((leaderboard: GroupLeaderboard) => <CarouselItem key={leaderboard.id}><Leaderboard leaderboard={leaderboard}/></CarouselItem>)}
    </Carousel>
}

export default GroupCarousel;
