import ChallengeCounter from "./ChallengeCounter";
import ChallengeContributorCount from "./ChallengeContributorCount";
import React from "react";
import MyChallengeContribution from "./MyChallengeContribution";

interface ChallengeDashboardProps {
    challengeId: number;
}

function ChallengeDashboard(props: ChallengeDashboardProps) {
    return <div>
        <ChallengeCounter challengeId={props.challengeId}/>
        <div className={"grid grid-cols-2 gap-6 mt-4"}>
            <MyChallengeContribution challengeId={props.challengeId}/>
            <ChallengeContributorCount challengeId={props.challengeId}/>
        </div>
    </div>
}

export default ChallengeDashboard;
