import {useAppDispatch} from "../app/hooks";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {pinLogin} from "../features/auth/authSlice";
import {object, string} from "yup";
import {TextField} from "formik-mui";
import {Button, useTheme} from "@mui/material";
import React from "react";
import ForgotPin from "./ForgotPin";
import UserSelector from "./UserSelector";
import {User} from "../Interfaces";
import {LoadingButton} from "@mui/lab";
import {eventLogger} from "../services/eventLogger";

interface LoginFormProps {
    onLogin: () => void,
    onCreateAccount: () => void,
}

interface Values {
    email: string;
    pin: string;
}

function LoginForm(props: LoginFormProps) {
    const [forgot, setForgot] = React.useState(false);
    const [user, setUser] = React.useState<User | string>("");
    let [error, setError] = React.useState("");
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const submit = (values: Values, {setSubmitting}: FormikHelpers<any>) => {
        if (!user || typeof user === 'string') {
            return;
        }
        dispatch(pinLogin({pin: values.pin, email: user.email}))
            .unwrap()
            .then((response: any) => {
                setSubmitting(false);
                setError("");
                props.onLogin();
            })
            .catch(e => {
                let error = "These credentials do not match our records.";
                setError(error);
                setSubmitting(false);
            });
    };
    if (forgot) {
        return <ForgotPin onBack={() => setForgot(false)}/>
    }
    return (<Formik
        initialValues={{email: "", pin: ""}}
        validationSchema={object({
            // email: string().required("Email is required").email("Email must be a valid email address"),
            pin: string().required("PIN is required")
        })}
        onSubmit={submit}
    >
        {({submitForm, isSubmitting, isValid, dirty}) => (
            <Form className={"flex flex-col grow"}>
                <div className={"flex-1 flex flex-col justify-center container mx-auto max-w-xl"}>
                    {/*<Field component={TextField} data-testid={"login-email"} id={"loginEmailAddress"} name={"email"} label="Email" variant="filled" type={"email"}*/}
                    {/*       required*/}
                    {/*       fullWidth margin={"dense"}/>*/}
                    <UserSelector onSelect={user => setUser(user)}/>
                    <Field component={TextField} data-testid={"login-pin"} id={"loginPin"} name={"pin"} label="PIN" variant="filled" type={"password"}
                           autoComplete={"off"}
                           required fullWidth
                           margin={"dense"}/>
                    {error &&
                        <p className={"text-red-500"}>The PIN you have provided is invalid. Please try again.</p>
                    }
                    <button onClick={() => {
                        eventLogger.track("pwa_about_you_forgot_pin");
                        setForgot(true);
                    }} type={"button"} data-testid={"reset-password-link"} className={"text-center p-3 mt-4 mx-auto block underline text-white"}>I forgot my PIN</button>
                </div>
                <div className={"flex-grow-0 mt-4 pt-4 sticky z-10 -mx-6 px-6 pb-6"} style={{bottom: 0, left: 0, right: 0, backgroundColor: theme.footer.background}}>
                    <div className={"container mx-auto max-w-xl"}>
                    <div className="mb-4">
                        <LoadingButton
                            loading={isSubmitting}
                            variant="contained"
                            color="primary"
                            disabled={!isValid || !dirty}
                            fullWidth
                            size={"large"}
                            sx={{mt: 1}}
                            type={"submit"}
                            data-testid={"login-submit"}
                        >
                            Next
                        </LoadingButton>
                    </div>
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        size={"large"}
                        className={'mt-4'}
                        onClick={() => {
                            eventLogger.track("pwa_about_you_create_account");
                            props.onCreateAccount();
                        }}
                    >Create An Account</Button>
                </div>
                </div>
            </Form>
        )}
    </Formik>);
}

export default LoginForm;
