import React from "react";

export default function Divider() {
    return <div className={"-mx-6"} style={{
        background: "#E9ECEF",
        mixBlendMode: "normal",
        opacity: 0.05,
        boxShadow: "inset 0px 1px 5px rgba(33, 37, 41, 0.199328)",
        height: "8px"
    }}></div>
};
