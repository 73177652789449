import "./Landing.css";
import {Link} from "react-router-dom";
import GroupLogo from "../ui/GroupLogo";
import {useAppSelector} from "../app/hooks";
import {selectStatus} from "../features/auth/authSlice";
import {Button, Theme, Typography, useTheme} from "@mui/material";
import {Routes} from "../RouteConstants";
import {selectGroupId} from "../features/group/groupSlice";
import {eventLogger} from "../services/eventLogger";
import BrawnLogo from "../ui/BrawnLogo";


function Landing() {
    let authState = useAppSelector(selectStatus);
    const theme: Theme = useTheme();
    let groupId = useAppSelector(selectGroupId);

    const BulletPoint = () => {
        return <><span style={{color: theme.palette.secondary.main}} className={'mr-2 text-2xl'}>&bull;</span>&nbsp;</>;
    }

    let pgCopy = <><Typography variant={"h2"} className={'uppercase text-white'}><span style={{color: theme.palette.secondary.main}}>Join the UK's largest</span> connected lifting community</Typography>
        <ul className={'text-white mt-6 list-disc text-lg list-none'}>
            <li className={"mt-4"}><BulletPoint/>Gamify your strength training</li>
            <li className={"mt-4"}><BulletPoint/>Get rewarded for your progress</li>
            <li className={"mt-4"}><BulletPoint/>Connect with like-minded lifters</li>
        </ul>
    </>;

    let brawnCopy = <><Typography variant={"h2"} className={'uppercase text-white'}><span style={{color: theme.palette.secondary.main}}>Common</span> goal</Typography>
        <Typography variant={"h2"} className={'uppercase text-white text-3xl font-medium mt-1'}>Personal <span style={{color: theme.palette.secondary.main}}>progress</span></Typography>
        <ul className={'text-white mt-6 list-disc text-lg list-none font-medium'}>
            <li className={'mt-4'}><BulletPoint/>Enter your lifts</li>
            <li className={'mt-4'}><BulletPoint/>Work harder together</li>
            <li className={'mt-4'}><BulletPoint/>Smash goals, earn rewards</li>
            <li className={'mt-4'}><BulletPoint/>Boost motivation and hit more PBs
            </li>
        </ul>
    </>;
    return <div className={'flex flex-col content-center min-h-screen justify-end'} id={"landing"} style={{background: theme.landing.background}}>
        <div className={'hero-image-background'} style={{backgroundImage: 'url(' + theme.landing.image + ')'}}></div>
        <div className={'hero'}>
            <div className="container mx-auto flex flex-col justify-center flex-1 p-6 max-w-xl">
                <div className={'mx-auto'}>
                    <img src={theme.logo} alt={"Brawn"} className={'md:h-10 flex-grow-0'}/>
                </div>
                <div className={'flex-1 mt-14 max-w-xl mx-auto mb-6'}>
                    {theme.brawn ? brawnCopy : pgCopy}
                    <div className={'mb-8'}>
                        {!theme.brawn &&
                            <>
                                <p style={{color: theme.landing.textColour}} className={'text-xs mt-8 mb-1'}>Powered by</p>
                                <BrawnLogo/>
                            </>
                        }
                    </div>
                </div>
                {groupId && authState === 'idle' &&
                    <div className={'pt-2 pb-8 h-[105px] w-[300px] mx-auto'}>
                        <GroupLogo groupId={groupId} className={'mx-auto'} edits={{
                            resize: {
                                maxWidth: 300,
                                height: 65,
                                fit: 'cover'
                            }
                        }}/>
                    </div>
                }
                <div className={'flex-grow-0'}>
                    <Link to={Routes.DASHBOARD} onClick={() => eventLogger.track('pwa_get_started')}>
                        <Button variant={"contained"} size={'large'} fullWidth>Get Started</Button>
                    </Link>
                    {groupId &&
                        <div className={'mt-4'}>
                            <Link to={Routes.GROUP_SELECT} onClick={() => eventLogger.track('pwa_not_your_gym')}>
                                <Button variant={'outlined'} size={"large"} fullWidth><span className={'text-white'}>Not your gym? Switch here</span></Button>
                            </Link>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
}

export default Landing;
