import LoginForm from "../ui/LoginForm";
import Header from "../ui/Header";
import React from "react";
import {useNavigate} from "react-router-dom";
import {Routes} from "../RouteConstants";

function Login() {
    let navigate = useNavigate();
    return <div className={'flex flex-col h-screen mx-auto'}>
        <div className={'flex-grow-0'}>
            <Header/>
        </div>
        <div className={'grow flex flex-col px-6'}>
            <h3 className={'uppercase text-center border border-t-0 border-l-0 border-r-0 border-b-red-600 font-bold py-2'}>Sign In</h3>
            <LoginForm onLogin={() => navigate(Routes.DASHBOARD)} onCreateAccount={() => navigate(Routes.REGISTER)}/>
        </div>
    </div>
}


export default Login;
