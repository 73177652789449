import {IconButton} from "@mui/material";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import WorkoutSummaryDate from "./workout-summary/WorkoutSummaryDate";
import WorkoutSummary from "./workout-summary/WorkoutSummary";
import React from "react";
import {useGetWorkoutSessionsByUserQuery} from "../services/apiClient";
import Loading from "./Loading";

interface WorkoutHistoryProps {
    userId: number;
}

function WorkoutHistory(props: WorkoutHistoryProps) {
    let [tab, setTab] = React.useState(0);
    let userId = props.userId;
    const {
        data: workouts,
        isFetching,
        isLoading,
        error
    } = useGetWorkoutSessionsByUserQuery(userId);
    if (isLoading || isFetching) {
        return <Loading/>;
    }
    let noData = <div className={'flex-grow flex flex-col justify-center h-100'}>
        <div className={"flex-1 flex flex-col justify-center"}>
            <div className={'px-10'}>
                <p className={'text-center font-bold mb-3'}>No training history yet!</p>
                <p className={`text-center text-white/50`}>Get access to detailed training insights and historical data by entering your lifts with Brawn.</p>
            </div>
        </div>
        {/*<div>*/}
        {/*    <Link to={Routes.LIFT_ENTRY}>*/}
        {/*        <Button*/}
        {/*            variant="contained"*/}
        {/*            color="primary"*/}
        {/*            fullWidth*/}
        {/*            size={"large"}*/}
        {/*            className={'mt-4'}*/}
        {/*            onClick={() => {*/}
        {/*                eventLogger.track("pwa_enter_lift");*/}
        {/*            }}*/}
        {/*        >Enter Lifts</Button>*/}
        {/*    </Link>*/}
        {/*</div>*/}
    </div>;
    if (error) {
        return noData;
    }
    if (workouts.data.result.length === 0) {
        return noData
    }
    let currentWorkoutId = workouts.data.result[tab];
    let currentWorkout = workouts.data.entities.workoutSessions[currentWorkoutId];

    return <>
        <div className={"flex items-center pb-6"}>
            <div className={"flex-none"}>
                <IconButton onClick={() => setTab(tab + 1)} disabled={tab === (workouts.data.result.length - 1)}>
                    <ChevronLeft/>
                </IconButton>
            </div>
            <WorkoutSummaryDate date={currentWorkout.created_at} className={'flex-grow'}/>
            <div className={"flex-none"}>
                <IconButton onClick={() => setTab(tab - 1)} disabled={tab === 0}>
                    <ChevronRight/>
                </IconButton>
            </div>
        </div>
        <div>
            <WorkoutSummary workoutSessionId={currentWorkoutId}/>
        </div>
    </>
}

export default WorkoutHistory;
