import React from "react";
import "./CarouselItem.css";

interface CarouselItemProps extends React.PropsWithChildren {

}

function CarouselItem(props: CarouselItemProps) {
    return <div className={"embla__slide px-6"}><>{props.children}</>
    </div>
}

export default CarouselItem;
