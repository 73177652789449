import React from "react";
import {useGetGroupActiveChallengeQuery} from "../../services/apiClient";
import Loading from "../Loading";
import ChallengeDashboard from "./ChallengeDashboard";

interface ActiveChallengeDashboardProps {
    groupId: number;
}

function ActiveChallengeDashboard(props: ActiveChallengeDashboardProps) {
    const {
        data: challengeData,
        isFetching,
        isLoading,
    } = useGetGroupActiveChallengeQuery(props.groupId);
    if (isFetching || isLoading) {
        return <Loading/>;
    }
    if (!challengeData) {
        return <></>
    }
    let challenge = challengeData.data.entities.groupChallenges[props.groupId];
    return <ChallengeDashboard challengeId={challenge.id}/>;
}

export default ActiveChallengeDashboard;
