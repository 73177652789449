import {Navigate, Route, Routes} from "react-router-dom";
import {Routes as Paths} from "./RouteConstants";
import Landing from "./pages/Landing";
import LiftEntry from "./pages/LiftEntry";
import WorkoutSessionSummary from "./pages/WorkoutSessionSummary";
import ResetPin from "./pages/ResetPin";
import SelectGroup from "./pages/SelectGroup";
import IntegrationWorkoutSessionSummary from "./pages/integration/IntegrationWorkoutSessionSummary";
import IntegrationWorkoutHistory from "./pages/integration/IntegrationWorkoutHistory";
import IntegrationBipsHistory from "./pages/integration/IntegrationBipsHistory";
import IntegrationBipsTarget from "./pages/integration/IntegrationBipsTarget";
import BipsHistory from "./pages/BipsHistory";
import Login from "./pages/Login";
import {User} from "./Interfaces";
import {PropsWithChildren} from "react";
import {useAppSelector} from "./app/hooks";
import {selectUser} from "./features/auth/authSlice";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";

interface ProtectedRouteProps extends PropsWithChildren {
    user: User | null,
    redirectPath?: string
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
    let {user, redirectPath, children} = props;
    redirectPath = redirectPath ?? Paths.LOGIN;
    if (!user) {
        return <Navigate to={redirectPath} replace/>;
    }

    return <>{children}</>;
};

const GuestRoute = (props: ProtectedRouteProps) => {
    let {user, redirectPath, children} = props;
    redirectPath = redirectPath ?? Paths.DASHBOARD;
    if (user) {
        return <Navigate to={redirectPath} replace/>;
    }

    return <>{children}</>;
};

function AppRouter() {
    let user = useAppSelector(selectUser);

    return <Routes>
        <Route path={Paths.LOGIN} element={<GuestRoute user={user}><Login/></GuestRoute>}/>
        <Route path={Paths.REGISTER} element={<GuestRoute user={user}><Register/></GuestRoute>}/>
        <Route path={Paths.DASHBOARD} element={<ProtectedRoute user={user}><Dashboard/></ProtectedRoute>}/>
        <Route path={Paths.LIFT_ENTRY} element={<ProtectedRoute user={user}><LiftEntry/></ProtectedRoute>}/>
        <Route path={Paths.WORKOUT_SESSION_SUMMARY} element={<ProtectedRoute user={user}><WorkoutSessionSummary/></ProtectedRoute>}/>
        <Route path={Paths.RESET_PIN} element={<GuestRoute user={user}><ResetPin/></GuestRoute>}/>
        <Route path={Paths.GROUP_SELECT} element={<ProtectedRoute user={user}><SelectGroup/></ProtectedRoute>}/>
        <Route path={Paths.BIPS_HISTORY} element={<ProtectedRoute user={user}><BipsHistory/></ProtectedRoute>}/>
        <Route path={Paths.APP_WORKOUT_SESSION_SUMMARY} element={<IntegrationWorkoutSessionSummary/>}/>
        <Route path={Paths.APP_TRAINING_HISTORY} element={<IntegrationWorkoutHistory/>}/>
        <Route path={Paths.APP_BIPS_HISTORY} element={<IntegrationBipsHistory/>}/>
        <Route path={Paths.APP_BIPS_TARGET} element={<IntegrationBipsTarget/>}/>
        <Route path={Paths.LANDING} element={<GuestRoute user={user}><Landing/></GuestRoute>}/>
        <Route path={"*"} element={<GuestRoute user={user}><Landing/></GuestRoute>}/>
    </Routes>
}

export default AppRouter;
