import React from "react";
import {sortBy} from "lodash";
import {useSelector} from "react-redux";
import {selectChallengeById, selectGroupChallengeMilestoneEntities} from "../../features/entities/entitiesSlice";
import {RootState} from "../../app/store";
import {ChallengeMilestone} from "../../Interfaces";

interface ChallengeCounterProps {
    challengeId: number;
}

interface DigitProps {
    char: string;
}

function Digit(props: DigitProps) {
    return <span
        className={"flex-1 bg-midnight-blue/75 rounded max-w-[2.5rem] min-w-[0.5rem] md:min-w-[2.5rem] px-1 py-6 text-center font-bold text-2xl mx-0.5"}>{props.char}</span>;
}

function Comma() {
    return <span className={"flex-0 font-bold mx-0.5 py-6 text-2xl text-center"}>,</span>
}

function ChallengeCounter(props: ChallengeCounterProps) {
    let challenge = useSelector((state: any) => selectChallengeById(state.entities, props.challengeId));
    let milestoneEntities = useSelector((state: RootState) => selectGroupChallengeMilestoneEntities(state.entities));
    if (!challenge || !milestoneEntities) {
        return <></>;
    }
    let milestones = Object.values(milestoneEntities).filter((milestone: ChallengeMilestone | undefined) => milestone &&  milestone.achieved_at === null && milestone.group_challenge_id === props.challengeId);
    let activeMilestone = sortBy(milestones, 'target')[0];
    let current = challenge.current || 0;
    let digits = current.toLocaleString()
        .split("")
        .map((char: string, i: number) => char === "," ? <Comma key={i}/> : <Digit key={i} char={char}/>);
    let target = activeMilestone ? activeMilestone.target : 0;
    return <div className={'rounded bg-black/25 py-6 px-3 flex flex-col'}>
        <h3 className={'font-bold text-left mb-4'}>{challenge.name}</h3>
        <div className={'px-3 min-w-full'}>
            <div className={'flex min-w-full md:min-w-fit flex-grow'}>
                <div className={"flex justify-end flex-grow"}>
                    {digits}
                </div>
                <div className={"px-1 shrink py-6 font-bold text-2xl w-[2.5rem] text-center"}>KG</div>
            </div>
        </div>
        {target &&
            <p className={'text-center text-white/50 mt-3'}>/ {target.toLocaleString()} KG total volume lifted</p>
        }
    </div>;
}

export default ChallengeCounter;
