import {CircularProgress} from "@mui/material";
import React from "react";

interface LoadingProps {
    className?: string;
}

function Loading(props: LoadingProps) {
    return <div className={"flex py-6 justify-center min-w-full " + props.className}><CircularProgress/></div>
}

export default Loading;
