import React from "react";
import {useSelector} from "react-redux";
import {selectChallengeById} from "../../features/entities/entitiesSlice";

interface ChallengeContributorCountProps {
    challengeId: number;
    className?: string;
}

function ChallengeContributorCount(props: ChallengeContributorCountProps) {
    let challenge = useSelector((state: any) => selectChallengeById(state.entities, props.challengeId));
    if (!challenge) {
        return <></>
    }
    let contributors = challenge.contributors ?? 0;
    return <div className={"rounded bg-black/25 py-6 px-3 " + props.className}>
        <h3 className={"font-bold text-left mb-4"}>Total Lifters</h3>
        <h1 className={"text-left text-2xl text-white/[0.7]"}>{contributors.toLocaleString()}</h1>
    </div>
}

export default ChallengeContributorCount;
