import {useGetMyChallengeContributionQuery} from "../../services/apiClient";
import React from "react";

interface MyChallengeContributionProps {
    challengeId: number;
    className?: string;
}

function MyChallengeContribution(props: MyChallengeContributionProps) {
    const {data, isLoading, error, isFetching} = useGetMyChallengeContributionQuery(props.challengeId);
    if(isFetching || isLoading || error){
        return <></>
    }
    let contribution = 1000;
    let stat = data.data.entities.stats[data.data.result];
    contribution = stat.value;
    return <div className={"rounded bg-black/25 py-6 px-3 " + props.className}>
        <h3 className={"font-bold text-left mb-4"}>You've contributed</h3>
        <h1 className={"text-left text-2xl text-white/[0.7]"}>{contribution.toLocaleString()} KG</h1>
    </div>
}

export default MyChallengeContribution;
