import {AxiosStatic} from "axios";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {authService} from "./auth.service";
import {Group, GroupChallenge, Insight, LiftVariation, ShoutOut, Stat, TopSet, User, WorkoutSession} from "../Interfaces";
import {
    GROUP,
    GROUP_CHALLENGES,
    groupChallengeSchema,
    groupSchema,
    INSIGHTS,
    insightSchema,
    LIFT_VARIATION,
    liftVariationSchema, shoutOutSchema,
    STATS,
    statSchema,
    topSetSchema,
    USER,
    userSchema,
    WORKOUT_SESSION,
    workoutSessionSchema
} from "../features/entities/entitiesSlice";
import {normalize} from "normalizr";

let apiClient: AxiosStatic;
apiClient = require("axios").default;
apiClient.defaults.baseURL = process.env.REACT_APP_API_URI;
apiClient.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
let version = process.env.REACT_APP_API_VERSION ?? "2022-08-18";
apiClient.defaults.headers.common["x-brawn-api-version"] = version;
apiClient.defaults.headers.common["x-brawn-source"] = "pwa";
apiClient.defaults.headers.common["Accept"] = "application/json";
apiClient.defaults.withCredentials = true;

export default apiClient;

export const brawnApi = createApi({
    reducerPath: "brawnApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URI,
        prepareHeaders: (headers, api) => {
            let authToken = authService.retrieveTokenFromStorage();
            if (authToken) {
                headers.set("Authorization", `Bearer ${authToken}`)
            }
            headers.set("x-brawn-api-version", version);
            headers.set("x-brawn-source", "pwa");
            headers.set("Accept", "application/json");
            headers.set("X-Requested-With", "XMLHttpRequest");
            // const name = "XSRF-TOKEN";
            // let cookie = {};
            // decodeURIComponent(document.cookie).split(";").forEach(el => {
            //     let [k, v] = el.split("=");
            //     (cookie as any)[k.trim()] = v;
            // });
            // let xsrfToken = (cookie as any)[name];
            // if (xsrfToken) {
            //     headers.set("X-XSRF-TOKEN", xsrfToken);
            // }
            return headers;
        },
        credentials: "include"
    }),
    tagTypes: ["Group", "LiftVariation", "WorkoutSession", "User", "WorkoutSessionInsights", "GroupChallenge"],
    endpoints: (builder) => ({
        getMe: builder.query({
            query: () => "/api/me",
            providesTags: ["User"],
            transformResponse(response: { data: User }) {
                authService.storeUser(response.data);
                let normalisedData = normalize(response.data, userSchema);
                return {data: normalisedData, key: USER};
            }
        }),
        getUserById: builder.query({
            query: (id: number) => `/api/user/${id}`,
            providesTags: ["User"],
            transformResponse(response: { data: User }) {
                let normalisedData = normalize(response.data, userSchema);
                return {data: normalisedData, key: USER};
            }
        }),
        searchGroups: builder.query({
            query: (term: string) => `/api/groups/search?term=${term}`,
            providesTags: ["Group"],
            transformResponse(response: { data: Group[] }) {
                let normalisedData = normalize(response.data, [groupSchema]);
                return {data: normalisedData, key: GROUP};
            }
        }),
        getGroup: builder.query({
            query: (id: number | string) => `/api/groups/${id}`,
            providesTags: (result, error, id) => [{type: 'Group', id}],
            transformResponse(response: { data: Group }, meta, arg) {
                let normalisedData = normalize(response.data, groupSchema);
                return {data: normalisedData, key: GROUP};
            }
        }),
        getUserGroups: builder.query({
            query: (userId: number) => `/api/groups/user/${userId}`,
            providesTags: ["Group"],
            transformResponse(response: { data: Group[] }) {
                let normalisedData = normalize(response.data, [groupSchema]);
                return {data: normalisedData, key: GROUP};
            }
        }),
        searchLiftVariations: builder.query({
            query: (term: string, simple: number = 1) => `/api/lift-variation/search?term=${term}&simple=${simple}`,
            providesTags: ["LiftVariation"],
            transformResponse(response: { data: LiftVariation[] }) {
                let normalisedData = normalize(response.data, [liftVariationSchema]);
                return {data: normalisedData, key: LIFT_VARIATION};
            }
        }),
        searchUsers: builder.query({
            query: (term: string) => `/api/user/search?term=${term}`,
            providesTags: ["User"],
            transformResponse(response: { data: User[] }) {
                let normalisedData = normalize(response.data, [userSchema]);
                return {data: normalisedData, key: USER};
            }
        }),
        register: builder.mutation({
            query: (body) => ({
                url: "/register",
                method: "POST",
                body
            })
        }),
        updateBipsTarget: builder.mutation({
            query: (body) => ({
                url: "/api/me/bips-target",
                method: "PUT",
                body
            }),
            transformResponse(response: { data: User }) {
                authService.storeUser(response.data);
                let normalisedData = normalize(response.data, userSchema);
                return {data: normalisedData, key: USER};
            }
        }),
        sendForgotPasswordLink: builder.mutation({
            query: (body) => ({
                url: "/password/email",
                method: "POST",
                body
            })
        }),
        resetPassword: builder.mutation({
            query: (body) => ({
                url: "/password/reset",
                method: "POST",
                body
            })
        }),
        createWorkoutSession: builder.mutation({
            query: (body) => ({
                url: "/api/workout-session",
                method: "POST",
                body
            }),
            transformResponse(response: { data: WorkoutSession }) {
                return {data: normalize(response.data, workoutSessionSchema), key: WORKOUT_SESSION};
            }
        }),
        getWorkoutSession: builder.query({
            query: (id: number) => `/api/workout-session/${id}`,
            providesTags: (result, error, id) => [{type: 'WorkoutSession', id}],
            transformResponse(response: { data: WorkoutSession }) {
                let normalisedData = normalize(response.data, workoutSessionSchema);
                return {data: normalisedData, key: WORKOUT_SESSION};
            }
        }),
        getWorkoutSessionsByUser: builder.query({
            query: (id: number) => `/api/user/${id}/workout-sessions`,
            providesTags: (result, error, id) => [{type: 'WorkoutSession', id}],
            transformResponse(response: { data: WorkoutSession[] }) {
                let normalisedData = normalize(response.data, [workoutSessionSchema]);
                return {data: normalisedData, key: WORKOUT_SESSION};
            }
        }),
        getWorkoutSessionInsights: builder.query({
            query: (id: number) => `/api/workout-session/${id}/insights`,
            providesTags: (result, error, id) => [{type: 'WorkoutSessionInsights', id}],
            transformResponse(response: { data: Insight[] }) {
                let normalisedData = normalize(response.data, [insightSchema]);
                return {data: normalisedData, key: INSIGHTS};
            }
        }),
        getBipsHistory: builder.query({
            query: (id: number) => `/api/user/${id}/workout-sessions/week-bips-history`,
            providesTags: ((result, error, id) => [{type: "WorkoutSessionInsights", id}]),
            transformResponse(response: { data: Stat[] }) {
                let normalisedData = normalize(response.data, [statSchema]);
                return {data: normalisedData, key: STATS};
            }
        }),
        getGroupActiveChallenge: builder.query({
            query: (id: number) => `/api/group/${id}/challenge/current`,
            providesTags: ((result, error, id) => [{type: "Group", id}]),
            transformResponse(response: { data: GroupChallenge }) {
                let normalisedData = normalize(response.data, groupChallengeSchema);
                return {data: normalisedData, key: GROUP_CHALLENGES};
            }
        }),
        getMyChallengeContribution: builder.query({
            query: (id: number) => `/api/group-challenge/${id}/my-contribution`,
            providesTags: (result, error, id) => [{type: "GroupChallenge", id}],
            transformResponse(response: { data: Stat }) {
                return {data: normalize(response.data, statSchema)};
            }
        }),
        getLeaderboardResultsByGroupAndPosition: builder.query({
            query: ({groupId, position}) => `/api/group/${groupId}/leaderboard/${position}/rankings`,
            providesTags: (result, error, {groupId}) => [{type: "Group", id: groupId}],
            transformResponse(response: { data: [TopSet] }) {
                return {data: normalize(response.data, [topSetSchema])};
            }
        }),
        getGroupShouts: builder.query({
            query: (id) => `/api/group/${id}/shout-outs`,
            providesTags: (result, error, id) => [{type: "Group", id}],
            transformResponse(response: { data: [ShoutOut] }) {
                return {data: normalize(response.data, [shoutOutSchema])};
            }
        })
    })

});

export const {
    useGetUserGroupsQuery,
    useRegisterMutation,
    useResetPasswordMutation,
    useSendForgotPasswordLinkMutation,
    useSearchGroupsQuery,
    useLazyGetGroupQuery,
    useGetGroupQuery,
    useSearchLiftVariationsQuery,
    useLazySearchLiftVariationsQuery,
    useCreateWorkoutSessionMutation,
    useGetWorkoutSessionQuery,
    useSearchUsersQuery,
    useGetWorkoutSessionInsightsQuery,
    useGetWorkoutSessionsByUserQuery,
    useGetBipsHistoryQuery,
    useGetUserByIdQuery,
    useGetMeQuery,
    useUpdateBipsTargetMutation,
    useGetGroupActiveChallengeQuery,
    useGetMyChallengeContributionQuery,
    useGetLeaderboardResultsByGroupAndPositionQuery,
    useGetGroupShoutsQuery
} = brawnApi;

