import useEmblaCarousel from "embla-carousel-react";
import React, {Children} from "react";
import "./Carousel.css";

interface CarouselProps extends React.PropsWithChildren {
    className?: string;

}

interface IndicatorProps {
    active: boolean;
}

function Indicator({active}: IndicatorProps) {
    let className = "text-4xl mx-0.5 ";
    className += active ? "text-white" : "text-white/50";
    return <span className={className}>&bull;</span>;
}

function Carousel({children, className}: CarouselProps) {
    const [emblaRef, emblaApi] = useEmblaCarousel();
    const [active, setActive] = React.useState(0);
    React.useEffect(() => {
        emblaApi && emblaApi.reInit();
    }, [children, emblaApi]);
    if (emblaApi) {
        emblaApi.on("settle", () => {
            setActive(emblaApi.selectedScrollSnap());
        });
    }
    return <div className={"embla " + className} ref={emblaRef}>
        <div className="embla__container">
            {children}
        </div>
        <div className={"flex justify-center"}>
            {Children.toArray(children).map((child, i) => <Indicator key={i} active={active === i}/>)}
        </div>
    </div>
}

export default Carousel;
