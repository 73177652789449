import React from "react";
import {Link} from "react-router-dom";
import {Routes} from "../RouteConstants";
import {ChevronLeft} from "@mui/icons-material";
import Logo from "./Logo";
import {Theme, useTheme} from "@mui/material";

interface HeaderProps {
    showBack?: boolean
}

const initialProps: HeaderProps = {
    showBack: false
}

function Header(props: HeaderProps = initialProps) {
    const theme: Theme = useTheme();

    return (
        <header style={{
            backgroundColor: theme.header.background,
        }}>
            <div className={'flex content-center items-center max-w-xl mx-auto justify-center'} id={"header"} style={{
                paddingTop: theme.header.padding.top,
                paddingBottom: theme.header.padding.bottom,
                paddingLeft: theme.header.padding.left,
                paddingRight: theme.header.padding.right,
            }}>
                {props.showBack && <Link to={Routes.LANDING} className={'my-auto flex-grow-0'}
                                         style={{color: theme.palette.getContrastText(theme.header.background ? theme.header.background : "#000")}}><ChevronLeft/></Link>}
                <Link to={Routes.DASHBOARD} className={'my-auto grow'}><Logo className={'mx-auto'}/></Link>
            </div>
        </header>
    )
}

export default Header;
