import {useGetUserGroupsQuery} from "../services/apiClient";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {selectGroupId, setGroupId} from "../features/group/groupSlice";
import {Group} from "../Interfaces";
import React from "react";
import {Avatar, List, ListItemButton, Menu, MenuItem, Typography} from "@mui/material";
import {assetUrl} from "../services/image";
import {ArrowDropDown} from "@mui/icons-material";

interface MyGroupSelectorProps {
    userId: number;
    className?: string;
}

interface GroupOptionProps {
    group: Group;
}

function GroupOption({group}: GroupOptionProps) {
    return <>
        {group.avatar_url && <Avatar src={assetUrl(group.avatar_url, 'xs')} sx={{width: 20, height: 20}}/>}
        {!group.avatar_url && <Avatar sx={{width: 20, height: 20}}><Typography>{group.short_name}</Typography></Avatar>}
        <Typography className={'pl-1.5'} fontWeight={700}>{group.display_name}</Typography></>
}

function MyGroupSelector(props: MyGroupSelectorProps) {
    const {data, isFetching, isLoading, error} = useGetUserGroupsQuery(props.userId);
    const groupId = useAppSelector(selectGroupId);
    const dispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    if (isFetching || isLoading || error) {
        return <></>
    }
    let options: Group[] = [];
    if (data && data.data.entities && data.data.entities.groups) {
        options = Object.values(data.data.entities.groups);
        if (groupId) {
            let number = options.findIndex((group: Group) => group.id === groupId);
            if (number >= 0 && selectedIndex !== number) {
                setSelectedIndex(number);
            }
        } else {
            let number = options.findIndex((group: Group) => group.is_home_group);
            if (number >= 0) {
                if (selectedIndex !== number) {
                    setSelectedIndex(number);
                }
                dispatch(setGroupId(options[number].id));
            }
        }
    }
    if (!options) {
        return <></>;
    }
    let selectedGroup = options[selectedIndex];
    return <div className={props.className + " bg-black/25 w-screen -mx-6"}>
        <div className={'mx-auto w-1/3'}>
            <List component="nav" aria-label="Selected Group" sx={{paddingTop: 0, paddingBottom: 0}}>
                <ListItemButton
                    id="group-select"
                    aria-haspopup="listbox"
                    aria-controls="group-menu"
                    aria-label="Select group"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickListItem}
                >
                    <GroupOption group={selectedGroup}/>
                    <ArrowDropDown/>
                </ListItemButton>
            </List>
            <Menu
                id="group-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: 'center',
                }}
            >
                {options.map((option, index) => (
                    <MenuItem
                        key={option.id}
                        onClick={(event) => handleMenuItemClick(event, index)}
                    >
                        <GroupOption group={option}/>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    </div>
}

export default MyGroupSelector;
