import {Box, Button, Tabs, useTheme} from "@mui/material";
import BrawnTab from "../ui/BrawnTab";
import {a11yProps, TabPanel} from "../ui/TabPanel";
import React from "react";
import WorkoutHistory from "../ui/WorkoutHistory";
import Header from "../ui/Header";
import {useAppSelector} from "../app/hooks";
import {selectUser} from "../features/auth/authSlice";
import {Link, Navigate} from "react-router-dom";
import {Routes} from "../RouteConstants";
import {eventLogger} from "../services/eventLogger";
import MyGroupSelector from "../ui/MyGroupSelector";
import {selectGroupId} from "../features/group/groupSlice";
import GroupCarousel from "../ui/GroupCarousel";
import ShoutOuts from "../ui/ShoutOuts";

function Dashboard() {
    const [value, setValue] = React.useState(0);
    const user = useAppSelector(selectUser);
    const groupId = useAppSelector(selectGroupId);
    let theme = useTheme();

    if (!user) {
        return <Navigate to={Routes.LOGIN}/>;
    }
    const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue);

    return <div className={'flex flex-col h-screen mx-auto'}>
        <div className={'flex-grow-0'}>
            <Header/>
        </div>
        <div className={'grow flex flex-col'}>
            <Box sx={{borderBottom: 1, borderColor: 'transparent', backgroundColor: theme.header.background}}>
                <Tabs
                    className={"container mx-auto max-w-xl"}
                    variant={'fullWidth'}
                    value={value}
                    onChange={handleChange}
                    sx={{px: 3}}
                    centered>
                    <BrawnTab label="Dashboard" {...a11yProps(0)} value={0}/>
                    <BrawnTab label="Community" {...a11yProps(1)} value={1}/>
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} className={value === 0 ? " grow" : ""}
                // style={{background: "url('/images/image_1.png') center center", backgroundSize: "cover"}}
            >
                <div className={'mb-12 flex-grow flex flex-col'}>
                    <WorkoutHistory userId={user.id}/>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1} className={value === 1 ? "flex-grow" : ""}>
                <MyGroupSelector userId={user.id} className={"-mt-6 mb-3"}/>
                {groupId &&
                    <>
                        <GroupCarousel groupId={+groupId} className={'-mx-6'}/>
                        <ShoutOuts groupId={+groupId} className={"mt-6"}/>
                    </>
                }
            </TabPanel>
        </div>
        <div className={"fixed p-6 bg-midnight-blue/75"} style={{bottom: 0, left: 0, right: 0}}>
            <Link to={Routes.LIFT_ENTRY}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size={"large"}
                    className={'mt-4'}
                    onClick={() => {
                        eventLogger.track("pwa_enter_lifts");
                    }}
                ><span className={"font-bold"}>Enter Lifts</span></Button>
            </Link>
        </div>
    </div>
}

export default Dashboard;
