import {useParams} from "react-router-dom";
import React from "react";
import WorkoutHistory from "../../ui/WorkoutHistory";

function IntegrationWorkoutHistory() {
    let params = useParams();
    let userId = params['userId'] ? +params['userId'] : -1;
    return <div className={'flex flex-col h-screen px-6 pt-6'}>
        <WorkoutHistory userId={userId}/>
    </div>
}

export default IntegrationWorkoutHistory;
