import BipsDial from "./BipsDial";
import StatBar from "./StatBar";
import InsightsBox from "./InsightsBox";
import WorkoutBreakdown from "./WorkoutBreakdown";
import React from "react";
import _ from "lodash";
import {useAppDispatch} from "../../app/hooks";
import {useSelector} from "react-redux";
import {selectTopSetEntities, selectWorkoutSessionById} from "../../features/entities/entitiesSlice";
import {brawnApi} from "../../services/apiClient";
import {RootState} from "../../app/store";
import {CircularProgress} from "@mui/material";
import {TopSet} from "../../Interfaces";
import Divider from "../Divider";

interface WorkoutSummaryProps {
    workoutSessionId: number;
}

function WorkoutSummary(props: WorkoutSummaryProps) {
    let {workoutSessionId} = props;
    let dispatch = useAppDispatch();
    let workoutSession = useSelector((state: any) => selectWorkoutSessionById(state.entities, +workoutSessionId));
    if (!workoutSession && workoutSessionId) {
        dispatch(brawnApi.endpoints.getWorkoutSession.initiate(workoutSessionId));
    }
    let topSetEntities = useSelector((state: RootState) => selectTopSetEntities(state.entities));
    if (!workoutSession) {
        return <CircularProgress/>;
    }
    let topSets: TopSet[] = [];
    if (workoutSession && topSetEntities) {
        topSets = workoutSession.top_sets.map(id => (typeof id === 'number' ? topSetEntities[id] : id) as TopSet);
    }
    let liftsCount = _.uniqBy(topSets, 'lift_variation_id').length;
    let repsCount = _.sumBy(topSets, 'reps');
    let setsCount = _.sumBy(topSets, 'sets');
    let volume = _.sumBy(topSets, topSet => topSet.reps * topSet.sets * topSet.weight);
    let bipsVolume = _.sumBy(topSets, 'bips') ?? 0;

    return <>
        <BipsDial current={bipsVolume} userId={workoutSession.user_id}/>
        <StatBar lifts={liftsCount} sets={setsCount} reps={repsCount} volume={volume} volumeUnit={'kg'} className={'my-4'}/>
        <Divider/>
        <InsightsBox workoutSessionId={workoutSession.id} className={"py-6"}/>
        <WorkoutBreakdown workoutSessionId={workoutSession.id} className={"py-6"}/>
    </>
}

export default WorkoutSummary;
