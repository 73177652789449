import React from "react";
import BipsHistoryChart from "../../ui/workout-summary/BipsHistoryChart";
import {useParams} from "react-router-dom";


function IntegrationBipsHistory() {
    let params = useParams();
    let userId = params['userId'] ? +params['userId'] : -1;
    return <div className={'flex flex-col h-screen'}>
        <div className={'px-6 grow container mx-auto max-w-xl'}>
            <BipsHistoryChart userId={userId}/>
        </div>
    </div>
}

export default IntegrationBipsHistory;
