import {useParams} from "react-router-dom";
import BipsHistoryChart from "../ui/workout-summary/BipsHistoryChart";
import React from "react";
import Header from "../ui/Header";

function BipsHistory(){
    let params = useParams();
    let userId = params['userId'] ? +params['userId'] : -1;
    return <div className={'flex flex-col h-screen'}>
        <Header/>
        <div className={'px-6 grow container mx-auto max-w-xl'}>
            <BipsHistoryChart userId={userId}/>
        </div>
    </div>
}

export default BipsHistory;
