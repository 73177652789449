import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {User} from "../../Interfaces";
import {authService} from "../../services/auth.service";
import {RootState} from "../../app/store";

export interface AuthState {
    user: User | null,
    status: "idle" | "loading" | "failed",
    error: string | null,
    token: string | null
}

const initialState: AuthState = {
    user: authService.retrieveUserFromStorage(),
    status: "idle",
    error: null,
    token: authService.retrieveTokenFromStorage()
};

interface LoginParams {
    email: string,
    password: string
}

interface PinLoginParams {
    email: string,
    pin: string
}

export const login = createAsyncThunk(
    "auth/login",
    async (params: LoginParams) => await authService.login(params.email, params.password)
);

export const pinLogin = createAsyncThunk(
    "auth/pin-login",
    async (params: PinLoginParams) => await authService.pinLogin(params.email, params.pin)
);

export const clientLogin = createAsyncThunk(
    "auth/client-login",
    async () => await authService.clientLogin()
)

// export const sanctumLogout = createAsyncThunk(
//     "auth/sanctum-logout",
//     async () => await authService.sanctumLogout()
// )

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: (state) => {
            authService.logout();
            state.user = null;
            state.token = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.status = "idle";
                state.user = action.payload;
                state.error = null;
                authService.storeUser(action.payload);
            })
            .addCase(login.rejected, (state, action) => {
                state.status = "failed";
                state.error = (action.error && action.error.message) ?? "Error";
            })
            .addCase(pinLogin.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(pinLogin.fulfilled, (state, action) => {
                state.status = "idle";
                state.user = action.payload.user;
                state.error = null;
                authService.storeUser(action.payload.user);
                authService.storeToken(action.payload.token);
            })
            .addCase(pinLogin.rejected, (state, action) => {
                state.status = "failed";
                state.error = (action.error && action.error.message) ?? "Error";
            })
            .addCase(clientLogin.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(clientLogin.fulfilled, (state, action) => {
                state.status = "idle";
                state.error = null;
                state.token = action.payload.access_token;
                authService.storeToken(action.payload.access_token);
            })
            .addCase(clientLogin.rejected, (state, action) => {
                state.status = "failed";
                state.error = (action.error && action.error.message) ?? "Error";
            });
    }
});

export const selectUser = (state: RootState) => state.auth.user;
export const selectToken = (state: RootState) => state.auth.token;
export const selectStatus = (state: RootState) => state.auth.status;
export const {logout} = authSlice.actions;
export default authSlice.reducer;
