import {GroupLeaderboard, LiftVariation, TopSet} from "../Interfaces";
import {useGetLeaderboardResultsByGroupAndPositionQuery} from "../services/apiClient";
import {sortBy} from "lodash";
import {ReactComponent as ChangeIcon} from '../icons/chevron.svg';
import {ReactComponent as NoChangeIcon} from '../icons/dash.svg';
import "./Leaderboard.css";
import {useLayoutEffect, useRef, useState} from "react";
import {animated, easings, useSpring} from "react-spring";

interface LeaderboardProps {
    leaderboard: GroupLeaderboard
}

interface LeaderboardHeaderProps {
    liftVariation: LiftVariation;
}

function LeaderboardHeader(props: LeaderboardHeaderProps) {
    return <div className={'bg-white/10 rounded py-2 px-4'}>
        <p className={'font-bold uppercase'}>{props.liftVariation.name}</p>
        <p className={'font-bold'}>Leaderboard</p>
    </div>
}

interface LeaderboardRowProps {
    name: string;
    rank: number;
    change: 'same' | 'up' | 'down';
    value: any;
    unit: string;
}

function LeaderboardRow({name, rank, change, value, unit}: LeaderboardRowProps) {
    // animation to show end of name
    const [flip, setFlip] = useState(false);
    const nameRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);

    useLayoutEffect(() => {
        const el = nameRef.current;

        if (!el) {
            return;
        }

        setWidth(el.offsetWidth);
    }, []);
    const {scroll} = useSpring({
        scroll: width,
        from: {scroll: 0},
        reset: true,
        reverse: flip,
        delay: !flip ? 3000 : 0,
        config: {
            duration: 4000,
            easing: easings.easeInOutCubic,
        },
        onRest: () => setFlip(!flip)
    });
    return <div className={'rounded bg-black/25 py-2 px-4 mt-3 flex items-center font-bold'}>
        <div className={'flex-0 change ' + change}>
            {change === "same" ? <NoChangeIcon/> : <ChangeIcon/>}
        </div>
        <div className={'flex-0 mr-2'}>#{rank}</div>
        <div className={'flex-grow'}>
            <animated.div
                className={"nameScroller"}
                scrollLeft={scroll}
                style={{
                    overflow: 'auto', // needs to be inline for spring
                }}
            >
                <div className={"nameHolder"} ref={nameRef}>
                    <span className={"name"}>{name}</span>
                </div>
            </animated.div>
        </div>
        <div className={"flex-0 "}>{value} {unit}</div>
    </div>
}

function Leaderboard({leaderboard}: LeaderboardProps) {
    const {data} = useGetLeaderboardResultsByGroupAndPositionQuery({groupId: leaderboard.group_id, position: leaderboard.position});
    let rows = Array(5);
    for (let i = 0; i < 5; i++) {
        rows[i] = <LeaderboardRow name={""} rank={i + 1} change={"same"} value={""} unit={""} key={i}/>
    }
    let unit = "kg";
    if (leaderboard.rank_by === 'bips') {
        unit = 'BIPs';
    }
    if (data && data.data.entities && data.data.entities.topSets) {
        let entities = data.data.entities;
        let topSets = sortBy(Object.keys(entities.topSets).map(id => ({...entities.topSets[id]})), "rank");
        topSets.forEach((topSet: TopSet, i) => {
            let value = topSet.weight;
            if (leaderboard.rank_by === 'bips') {
                value = topSet.bips;
            }
            let user = entities.users[topSet.user_id];
            rows[i] = <LeaderboardRow name={user.first_name + " " + user.last_name} rank={topSet.rank} change={topSet.change} value={value} unit={unit} key={topSet.id}/>;
        })
    }
    return <div>
        <LeaderboardHeader liftVariation={leaderboard.lift_variation}/>
        {rows}
    </div>
}

export default Leaderboard;
