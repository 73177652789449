import apiClient from "./apiClient";
import {User} from "../Interfaces";
import mixpanel from "mixpanel-browser";

interface PinLoginResponse {
    data: {
        token: string,
        user: User
    }
}

interface PasswordLoginResponse {
    data: User
}

const login = (email: string, password: string) => {
    // return apiClient.get("/sanctum/csrf-cookie").then(() => {
        return apiClient.post("/login", {email, password})
            .then((data: PasswordLoginResponse) => {
                identifyWithMixPanel(data.data);
                return data.data;
            });
    // });
};

const pinLogin = (email: string, pin: string) => {
    // return apiClient.get("/sanctum/csrf-cookie").then(() => {
        return apiClient.post("/api/pin-login", {email, pin})
            .then((data: PinLoginResponse) => {
                identifyWithMixPanel(data.data.user);
                return data.data;
            });
    // });
};

const identifyWithMixPanel = (user: User) => {
    let token = process.env.REACT_APP_MIX_PANEL_TOKEN as string;
    mixpanel.init(token, {debug: process.env.NODE_ENV !== 'production'});
    mixpanel.identify(user.id.toString());
}

const me = () => {
    // return apiClient.get("/sanctum/csrf-cookie").then(() => {
        let headers = {
            Authorization: "Bearer " + retrieveTokenFromStorage(),
            "x-brawn-api-version": process.env.REACT_APP_API_VERSION ?? "2022-08-18"
        };
        return apiClient.get("/api/me", {headers});
    // });
}

const clientLogin = () => {
    return apiClient.post("/oauth/token", {
        grant_type: "client_credentials",
        client_id: process.env.REACT_APP_API_CLIENT_ID,
        client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
        scope: "*"
    })
        .then(data => data.data);
}

const requestPinReset = (email: string) => apiClient.post("/pin/email", {email})
const resetPin = (data: { pin: string, pin_confirmation: string, email: string, token: string }) => apiClient.post("/pin/reset", data);

// const sanctumLogout = () => apiClient.post("/api/sanctum/logout").then(() => logout());

const storeUser = (user: User) => {
    localStorage.setItem("user", JSON.stringify(user));
};
const storeToken = (token: string) => {
    localStorage.setItem("token", JSON.stringify(token));
};

const retrieveUserFromStorage = (): User => {
    let item = localStorage.getItem("user");
    return item ? JSON.parse(item) : null;
};

const retrieveTokenFromStorage = (): string | null => {
    let item = localStorage.getItem('token');
    return item ? JSON.parse(item) : null;
}

const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
}

const handleToken = (token: string) => {
    // logging out current session
    // sanctumLogout()
    //     .then(() => {
    logout();
    storeToken(token);
    // get new cookie from sanctum
    // use the token
    me()
        .then(data => {
            let user = data.data.data;
            storeUser(user);
            identifyWithMixPanel(user);
        })
        .catch(e => {
            // console.log(e);
            // sanctumLogout().then(() => {
            //     return clientLogin();
            // });
        });
    // });
}

export const authService = {
    login,
    pinLogin,
    clientLogin,
    storeUser,
    retrieveUserFromStorage,
    storeToken,
    retrieveTokenFromStorage,
    logout,
    requestPinReset,
    resetPin,
    me,
    identifyWithMixPanel,
    handleToken
};
