import {BarController, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineController, LineElement, PointElement, Title, Tooltip} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import Label from "../../icons/label.svg";
import StarIcon from "../../icons/icon_star.svg";
import {CircularProgress, IconButton} from "@mui/material";
import {ChevronLeft} from "@mui/icons-material";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Routes} from "../../RouteConstants";
import {ReactComponent as CogIcon} from "../../icons/icon_cog.svg";
import {Chart} from "react-chartjs-2";
import React from "react";
import {DateTime} from "luxon";
import {Stat} from "../../Interfaces";
import {useAppSelector} from "../../app/hooks";
import {selectUser} from "../../features/auth/authSlice";
import {useSelector} from "react-redux";
import {selectUserById} from "../../features/entities/entitiesSlice";
import {useGetBipsHistoryQuery} from "../../services/apiClient";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    LineController,
    BarController,
    annotationPlugin
);
const chartAreaFill = {
    id: 'custom_chart_background_color',
    beforeDraw: (chart: ChartJS, args: any, options: { color: string }) => {
        const {ctx, chartArea: {left, top, width, height}} = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = options.color;
        ctx.fillRect(left, top, width, height);
        ctx.restore();
    },
    defaults: {
        color: "rgba(0, 0, 0, 0.2)"
    }
}

const targetLabel = {
    id: "targetLabel",
    afterDatasetsDraw: (chart: ChartJS, args: any, options: any) => {
        const {ctx, scales: {y}, chartArea: {left}} = chart;
        const {goal} = options;
        ctx.save();
        let image = new Image();
        image.onload = () => {
            let width = 45;
            let height = 18
            let dx = left - width;
            let dy = y.getPixelForValue(goal) - (height / 2);
            ctx.drawImage(image, dx, dy, width, height);
            ctx.fillText(goal, dx + 5, dy + 13);
        }
        image.src = Label;
        ctx.restore();
    },
    defaults: {
        goal: 1500
    }
}

const barStar = new Image();
barStar.src = StarIcon;

const options = {
    plugins: {
        legend: {
            display: false
        },
        annotation: {},
        targetLabel: {}
    },
    elements: {
        bar: {
            borderRadius: {
                topLeft: 4,
                topRight: 4,
                bottomLeft: 0,
                bottomRight: 0,
            },
        }
    },
    scales: {
        y: {
            grid: {
                // display: false,
                color: "rgba(255,255,255,0.2)"
            },
            ticks: {
                color: "white",
                stepSize: 500
            }
        },
        x: {
            grid: {
                display: false,
                color: "white"
            },
            ticks: {
                color: "white"
            }
        }
    }
};

interface LocationState {
    from?: string
}

interface BipsHistoryChartProps {
    userId: number
}

function BipsHistoryChart(props: BipsHistoryChartProps) {
    let navigate = useNavigate();
    const location = useLocation();
    let authUser = useAppSelector(selectUser);
    let state = location.state as LocationState;
    let userId = props.userId;
    let user = useSelector((state: any) => selectUserById(state.entities, +userId));
    const [target, setTarget] = React.useState(user ? user.bips_target : 1500);
    const {
        data: stats,
        isFetching,
        isLoading,
        error
    } = useGetBipsHistoryQuery(userId);
    React.useEffect(() => {
        if (user) {
            setTarget(user.bips_target);
        }
    }, [user]);
    if (error) {
        return <></>
    }
    if (isLoading || isFetching) {
        return <div className={"flex py-6 justify-center"}><CircularProgress/></div>;
    }
    let scaffold = [];
    let date = DateTime.now().setZone('UTC').startOf('day');
    for (let i = 0; i <= 6; i++) {
        let modifiedDate = date.minus({days: i});
        let label = modifiedDate.toFormat('ccccc');
        if (!stats.data.entities.hasOwnProperty('stats')) {
            scaffold.push({label, value: 0});
            continue;
        }
        let find: Stat = Object.values(stats.data.entities.stats).find((stat: Stat | any) => {
            return modifiedDate.toISODate() === stat.meta.date;
        }) as Stat;
        if (find) {
            scaffold.push({label, value: find.value});
            continue;
        }
        scaffold.push({label, value: 0});
    }
    scaffold = scaffold.reverse();
    let annotationConfig: any = {
        annotations: {
            line1: {
                type: "line",
                yMin: target,
                yMax: target,
                borderColor: "white",
                borderWidth: 2
            }
        }
    };
    scaffold.forEach((point, i) => {
        if (point.value >= target) {
            let key = `point${i}`;
            annotationConfig.annotations[key] = {
                type: "point",
                xValue: i,
                yValue: point.value - 100,
                pointStyle: barStar
            };
        }
    });
    options.plugins.annotation = annotationConfig;
    options.plugins.targetLabel = {goal: target};
    let data = {
        labels: scaffold.map(point => point.label),
        datasets: [
            {
                type: "bar" as const,
                label: "BIPs",
                data: scaffold.map(stat => stat.value),
                backgroundColor: "rgba(247, 198, 255, 0.7)",
                barThickness: 20,
            }
        ]
    };

    let from = state && state.from && state.from as string;
    const goBack = () => from ? navigate(from) : navigate(-1);
    return <>
        <div className="flex items-center py-6">
            <IconButton className={'flex-none'} onClick={goBack}><ChevronLeft/></IconButton>
            <h3 className={'flex-grow text-center text-lg'}><strong>BIPs achieved in the last 7 days</strong></h3>
            {authUser && +authUser.id === +userId &&
                <Link to={Routes.APP_BIPS_TARGET} state={state}><IconButton className={'flex-none'}><CogIcon/></IconButton></Link>
            }
        </div>
        <div>
            <Chart type={"bar"} data={data} options={options} plugins={[chartAreaFill, targetLabel]}/>
        </div>
    </>;
}

export default BipsHistoryChart;
