import Header from "../ui/Header";
import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectWorkoutSessionById} from "../features/entities/entitiesSlice";
import {useAppDispatch} from "../app/hooks";
import {brawnApi} from "../services/apiClient";
import {Button, CircularProgress, useTheme} from "@mui/material";
import {Routes} from "../RouteConstants";
import React, {useEffect} from "react";
import {eventLogger} from "../services/eventLogger";
import WorkoutSummaryDate from "../ui/workout-summary/WorkoutSummaryDate";
import WorkoutSummary from "../ui/workout-summary/WorkoutSummary";

function WorkoutSessionSummary() {
    const [tracked, setTracked] = React.useState(false);
    const theme = useTheme();
    useEffect(() => {
        if (!tracked) {
            eventLogger.track("pwa_great_lift_view");
            setTracked(true);
        }
    }, [tracked])
    let params = useParams();
    let workoutSessionId = params['workoutSessionId'] ? +params['workoutSessionId'] : -1;
    let dispatch = useAppDispatch();
    let workoutSession = useSelector((state: any) => selectWorkoutSessionById(state.entities, +workoutSessionId));
    if (!workoutSession && workoutSessionId) {
        dispatch(brawnApi.endpoints.getWorkoutSession.initiate(workoutSessionId));
    }
    if (!workoutSession) {
        return <div className={"flex py-6 justify-center"}><CircularProgress/></div>;
        ;
    }
    return <div className={'flex flex-col h-screen'}>
        <Header/>
        <div className={'px-6 grow mt-6 container mx-auto max-w-xl'}>
            <WorkoutSummaryDate date={workoutSession.created_at} className={"mb-6"}/>
            <WorkoutSummary workoutSessionId={workoutSessionId}/>
        </div>
        <div className={'p-6'} style={{backgroundColor: theme.footer.background}}>
            <div className={"container mx-auto max-w-xl"}>
                <Link to={Routes.LIFT_ENTRY} onClick={() => eventLogger.track("pwa_great_lift_enter_more")}>
                    <Button fullWidth variant={"contained"} size={"large"} className={'uppercase'}>Enter More Lifts</Button>
                </Link>
            </div>
        </div>
    </div>
}

export default WorkoutSessionSummary
