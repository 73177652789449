import React from "react";
import LiftsDataEntry, {LiftsSubmission} from "../ui/LiftsDataEntry";
import {useCreateWorkoutSessionMutation} from "../services/apiClient";
import Header from "../ui/Header";
import {useAppSelector} from "../app/hooks";
import {selectGroupId} from "../features/group/groupSlice";
import {useNavigate} from "react-router-dom";
import {Routes} from "../RouteConstants";
import MyGroupSelector from "../ui/MyGroupSelector";
import {selectUser} from "../features/auth/authSlice";

interface WorkoutSessionPayload {
    groups?: number[],
    scope?: number,
    lifts: {
        lift_variation_id: number,
        sets: {
            set_number: number,
            reps: number,
            weight: number
        }[]
    }[]
}


export function liftsDataIsValid(liftsData: LiftsSubmission) {
    if (liftsData.lifts.length === 0) {
        return false;
    }
    let valid = true;
    liftsData.lifts.forEach(lift => {
        if (!lift.liftVariation) {
            valid = false;
        }
        if (lift.sets.length === 0) {
            valid = false;
        }
        lift.sets.forEach(set => {
            if (!(set.reps > 0)) {
                valid = false;
            }
            if (!(set.weight >= 0)) {
                valid = false;
            }
        });
    });
    return valid;
}

function LiftEntry() {
    const [errors, setErrors] = React.useState("");
    const user = useAppSelector(selectUser);
    const [liftsData, setLiftsData] = React.useState<LiftsSubmission>({lifts: [{liftVariation: "", sets: [{weight: "", reps: ""}]}]});
    const [createWorkoutSession] = useCreateWorkoutSessionMutation();
    const groupId = useAppSelector(selectGroupId);
    const navigate = useNavigate();
    const [saving, setSaving] = React.useState(false);
    React.useEffect(() => {
        if (groupId && (!liftsData.groups || +groupId !== +liftsData.groups[0])) {
            setLiftsData({...liftsData, groups: [+groupId]});
        }
    }, [groupId, liftsData]);

    const handleLiftSubmit = (data: LiftsSubmission) => {
        setLiftsData(data);
        setSaving(true);
        let groups = liftsData.groups ? liftsData.groups : [];
        let payload = {
            groups: groups,
            scope: groups.length > 0 ? 2 : 1,
            lifts: liftsData.lifts.map(lift => {
                return {
                    lift_variation_id: typeof lift.liftVariation !== 'string' ? lift.liftVariation.id : lift.liftVariation,
                    sets: lift.sets.map((set, i) => {
                        return {
                            set_number: i + 1,
                            weight: set.weight,
                            reps: set.reps
                        }
                    })
                }
            })
        } as WorkoutSessionPayload;
        createWorkoutSession(payload).unwrap()
            .then(response => {
                setSaving(false);
                navigate(Routes.DASHBOARD);
            })
            .catch(error => {
                setSaving(false);
                setErrors(error.data.errors);
            });
    }

    return <div className={'flex flex-col h-screen mx-auto px-6'}>
        <div className={'flex-grow-0'}>
            <Header/>
        </div>
        <div className={'grow flex flex-col'}>
            <h3 className={'uppercase text-center border border-t-0 border-l-0 border-r-0 border-b-red-600 font-bold py-3 border-b-2'}>Your Lifts</h3>
            {user && <MyGroupSelector userId={user.id}/>}
            <LiftsDataEntry onSubmit={handleLiftSubmit} data={liftsData} errors={errors} saving={saving}/>
        </div>
    </div>
}

export default LiftEntry;
